'use strict';

function ntvVideoplayer($container) {
	
	var videoSetup = $container.data( 'player' ).setup,
		mountPoint = $container[0],
		loaderConfig = {
			client: 'rtldigitalnews',
			scope: 'ntv',
			//preset: 'ntv',
			//platform: 'web',
			environment: location.host.includes("preview.")?'preprod':'prod',
			pluginConfigs: {
				'foundation.plugin.heartbeat': {
					enabled: true,
					endpoint: {
						vod: 'https://px1.vtrtl.de/vt/hb.do',
						live: 'https://px1.vtrtl.de/vtl/hb.do'
					},
					isKidsProfile: false,
					offer: videoSetup.tracking.angebot,
					payStatus: 0,
					userStatus: 0,
					videoService: videoSetup.tracking.videoservice
				},
				'foundation.plugin.googleAnalytics4': {
					enabled: true
				},
				'foundation.plugin.nielsen': {},
				'foundation.plugin.npawAnalytics': {
					appName: 'ntv'
				},
				'foundation.plugin.advertising': {
					smartclipConfig: {
						adReinsertion: {
							homad: {
								enabled: ntv.disable.homad?false:true,
								setup: {
									clientConfig: resourceBaseUrl + '/adaptive/videoplayerNew/homadClientConfig.json'
								}
							}
						}
					}
				}
			}
		},
		uiConfig = {
			poster: videoSetup.source.poster && videoSetup.source.poster!=""?videoSetup.source.poster:'',
			allowNativeVideoFullscreen: true
		},
		mediaConfig = getMediaConfig(videoSetup);
		
		if(ntv.consents.info.tcString){
			loaderConfig.consentString = ntv.consents.info.tcString;
		}
		
		if(ntv.pageInfo.pmode){
			loaderConfig.pluginConfigs['foundation.plugin.heartbeat'].enabled = false;
			loaderConfig.pluginConfigs['foundation.plugin.googleAnalytics4'].enabled = false;
			loaderConfig.pluginConfigs['foundation.plugin.nielsen'].enabled = false;
			loaderConfig.pluginConfigs['foundation.plugin.npawAnalytics'].enabled = false;
			loaderConfig.pluginConfigs['foundation.plugin.advertising'].enabled = false;
		}
		
		ntvDebug(loaderConfig);
		ntvDebug(mediaConfig);
	
	
	if(!window.ntvPlayer){
		$container.html("");
		try {
			window.foundation.ui.createPlayerUI(mountPoint, loaderConfig, uiConfig)
				.then(function(player){
					handlePlayerEvents($container,player);
					window.ntvPlayer = player;
					player.loadMedia(mediaConfig);
				});
		} catch (e) {
			console.error('Error:', e);
		}
	}else{
		ntvPlayer.loadMedia(mediaConfig);
	}
}


function getMediaConfig(videoSetup){
	
	var getAdCall = function(){
		
		var baseUrl = 'https://ad-ipd.sxp.smartclip.net/select',
			params = {
				type: 'vast4', // Definition der AdserverResponse
				fwd_adb: '[BLOCKED]', // placeholder
				s: videoSetup.advertising.adCall.category,
				sz: 'pre', // für Preroll. anpassen, wenn es weitere Slots gibt!!!
				tagver: '{TAGVER}', // placeholder, Wert kommt aus dem Player
				fwd_comp: '0', // ??? sind Companion Adserlaubt? (1 ja, 0 nein?)
				//fwd_vpaid: '0', // ???(Norman) sind interaktive VPAIDAds erlaubt? (1 ja, 0 nein?)
				ang_cntp: 'none', // Name des contentpartner
				ang_streamid: window.crypto && crypto.randomUUID?crypto.randomUUID():'', // random UUID
				ang_conlen: videoSetup.source.length, // Content length, Längedes Content Videos inSekunden
				fwd_conid: videoSetup.source.videoId, // Video ID
				rnd: Math.floor(Math.random() * (9999999999999999 - 10000000)) + 10000000, // positive Zufallszahl,mindestens 8 Zeichen(i64)
				ang_plrw: '{PLAYER_WIDTH}', // Player Breite placeholder
				ang_plrh: '{PLAYER_HEIGHT}', // Player Hoehe placeholder
				consent: '{CONSENT}' // placeholder
			},
			adCall = baseUrl + '?';
		
	    for(var key in params) {
	    	adCall += key + '=' + params[key] + '&';
	    }
	    
	    if(window.$AC){
			$AC.setTargeting("vcd",videoSetup.source.length);
			adCall += $AC.getSXPKeys();
		}
		
		// getSXPKeysAsync()
		
		return adCall;
	};
	
	var mediaConfig = {
		id: videoSetup.source.videoId,
        type: videoSetup.livestream?'live':'vod',
        autoplay: videoSetup.playback && videoSetup.playback.autoplay==false?false:true,
        muted: false,
		advertising: {
			slots: [{
				adCall: getAdCall(),
				offset: 0
			}]
		},
		plugins: {
			'foundation.plugin.heartbeat': {
				id: videoSetup.source.videoId,
				ivw: videoSetup.source.ivw,
				startType: videoSetup.source.startType || 0
			},
			'foundation.plugin.googleAnalytics4': {
				id: videoSetup.source.videoId,
				contentType: videoSetup.livestream?'videolivestream':'video',
				firstPublicationDate: videoSetup.source.pubDate,
				headline: videoSetup.source.title,
				keywords: videoSetup.source.keywords
			},
			'foundation.plugin.nielsen': {
				commentPath: videoSetup.source.ivw.substring(0,1)=='/'?videoSetup.source.ivw.substring(1):videoSetup.source.ivw
			},
			'foundation.plugin.npawAnalytics': {
				channelName: 'ntv',
				id: videoSetup.source.videoId,
				title: videoSetup.source.title
			}
		},
		metadata: {
			poster: videoSetup.source.poster && videoSetup.source.poster!=""?videoSetup.source.poster:''
		},
        manifests: []
	};
	
	videoSetup.source.progressive && mediaConfig.manifests.push({
		type: 'progressive',
		sources: [{
			url: videoSetup.source.progressive,
			priority: 'main'
		}]
	});
	
	videoSetup.source.dash && mediaConfig.manifests.push({
		type: 'dashhd',
		sources: [{
			url: videoSetup.source.dash,
			priority: 'main'
		}],
		licenseServers: [{
			address: 'https://widevine.n-tv.de/index/proxy',
			encryptionType: 'widevine'
		},
		{
			address: 'https://playready-core.n-tv.de/playready/api/RightsManager.asmx',
			encryptionType: 'playready'
		}]
	});
	
	videoSetup.source.hls && mediaConfig.manifests.push({
		type: 'hlsfairplayhd',
		sources: [{
			url: videoSetup.source.hls,
			priority: 'main'
		}],
		licenseServers: [{
			address: 'https://fairplay.n-tv.de/Fairplay/GetLicense',
			certificate: 'https://fairplay.n-tv.de/Certificate/RTLiDe_cert.cer',
			encryptionType: 'fairplay'
		}]
	});
	
	return mediaConfig;
}

function handlePlayerEvents($container, player){
	
	var trackingDone = false;		
	
	player.addEventListener("player:Error", function(e){
		if(!player.getAdvertisingAdapter().isAd()){
			if((e.originalEvent && e.originalEvent.data && e.originalEvent.data.statusCode===403) || (e.code>=12100 && e.code<12200)){
				player.destroy();
				$container.removeAttr('class');
				$container.html('<span class="article__media__error"><p>Leider ist dieses Video nicht verfügbar. Grund könnte Ihr aktueller Standort sein.</p><p>Wir freuen uns aber, Sie als Leser informativ und kurzweilig auf dem Laufenden halten zu können.</p></span>');
			}
		}
	});
	
	player.addEventListener("player:Playing", function(){
		window.dispatchEvent( new CustomEvent('videoplayerPlaying') );
		if( !trackingDone ) {
			ntvTracking({
				trackIVW: true,
				trackingSet: $container.data( 'trackingSet' )
			});
			trackingDone = true;
		}
	});
	
	player.addEventListener("player:Paused", function(){
		window.dispatchEvent( new CustomEvent('videoplayerPaused') );
	});
	
	player.addEventListener("advertising:AdStarted", function(){
		gaEvent({
			eventCategory: 'videomessung',
			eventAction: 'Preroll',
			eventLabel: 'Ad-start'
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'videomessung',
			'eventaction': 'Preroll',
			'eventlabel': 'Ad-start'
		});
	});
	
	player.addEventListener("player:ContentStart", function(){
		gaEvent({
			eventCategory: 'videomessung',
			eventAction: 'Content',
			eventLabel: 'Content-Start'
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'videomessung',
			'eventaction': 'Content',
			'eventlabel': 'Content-Start'
		});
	});
	
	player.addEventListener("player:ContentEnd", function(){
		gaEvent({
			eventCategory: 'videomessung',
			eventAction: 'Content',
			eventLabel: 'Content-Ende'
		});
		dataLayer.push({
			'event': 'ga4_event' ,
			'eventcategory': 'videomessung',
			'eventaction': 'Content',
			'eventlabel': 'Content-Ende'
		});
	});
	
	player.addEventListener("player:SessionEnd", function(){
		
		window.dispatchEvent( new CustomEvent('videoplayerSessionEnd') );
		if( $container.data('playlist') && $container.data('playlist').ajaxUrl && $container.data('playlist').videoIds.length > 0 ){
			 
			var playlistSetup = $container.data('playlist');
			 
			var ajaxUrl = playlistSetup.ajaxUrl + "&ctxArt=" + playlistSetup.videoIds[0];
		 	
		 	$container.data('playlist').videoIds.shift();

			$.ajax({
				url: ajaxUrl,
				type: "GET"
			})
			.done( function( data, textStatus, jqXHR ) {
				$(".ajax-wrapper", ".article--video").html(data);
				window.dispatchEvent( new CustomEvent('videoplayerNewVideo') );
				ntvVideoplayer( $("#playerwrapper") );

			})
			.fail( function( jqXHR, textStatus, errorThrown ) {
				//ntv.log( jqXHR, textStatus, errorThrown );
			});

		 }
	});
}